import React, { useEffect, useState } from "react";

import Header, { DrawerHeader } from "./template/Header";
import Status from "./mapping/Status";
import Title from "./template/Title";

import { API, graphqlOperation } from "aws-amplify";
import { listBcdImporters } from "../../graphql/queries";

import { ThemeProvider } from "@mui/material/styles";
import DefaultTheme, { DefaultStyles } from "../common/Themes";

import { Box, IconButton, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";

const Importers = () => {
  const classes = DefaultStyles();

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    { field: "updatedAt", sort: "desc" },
  ]);

  const columns = [
    {
      align: "center",
      field: "importerStatus",
      headerAlign: "center",
      headerName: "Status",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <Status code={params.row.importerStatus} />;
      },
    },
    {
      field: "id",
      headerName: "Importer ID",
      hide: true,
      disableColumnMenu: true,
      sortable: false,
      width: 300,
    },
    { field: "customsRef", headerName: "Customs Ref", width: 150 },
    { field: "importerName", headerName: "Importer", width: 150 },
    { field: "importerAddress", headerName: "Address", width: 200 },
    { field: "importerCity", headerName: "City", width: 150, hide: true },
    { field: "importerState", headerName: "Parish", width: 100 },
    { field: "importerZip", headerName: "Postal", width: 100 },
    { field: "importerCountry", headerName: "Country", width: 120, hide: true },
    {
      field: "createdAt",
      headerName: "Created",
      width: 120,
      renderCell: (params) => {
        return params.row.action.created;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      width: 120,
      renderCell: (params) => {
        return params.row.action.updated;
      },
    },
    {
      align: "center",
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => alert(JSON.stringify(params.row.action.id))}
          >
            <Edit />
          </IconButton>
        );
      },
    },
  ];
  // const rows = [{ id: 1, firstName: "Bill" }];
  const [rows, setRows] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        let data = null;
        const query = { limit: 10000 };
        const response = await API.graphql(
          graphqlOperation(listBcdImporters, query)
        );

        // Check if query returned expected data
        if (response.hasOwnProperty("data")) {
          data = response["data"].listBcdImporters.items;
          data.sort((a, b) => {
            if (a.importerName < b.importerName) return -1;
            return a.importerName > b.importerName ? 1 : 0;
          });

          const rows = [];
          data.forEach((u, idx) => {
            var options = {
              year: "numeric",
              month: "short",
              day: "numeric",
            };
            const cdate = new Date(u.createdAt);
            const udate = new Date(u.updatedAt);

            rows.push({
              // id: idx + 1,
              id: u.id,
              customsRef: u.customsRef,
              importerStatus: u.importerStatus,
              importerName: u.importerName,
              importerAddress: u.importerAddress ? u.importerAddress : "-",
              importerCity: u.importerCity ? u.importerCity : "-",
              importerState: u.importerState ? u.importerState : "-",
              importerZip: u.importerZip ? u.importerZip : "-",
              importerCountry: u.importerCountry ? u.importerCountry : "-",
              createdAt: u.createdAt,
              updatedAt: u.updatedAt,
              action: {
                id: u.id,
                created: cdate.toLocaleDateString("en-US", options),
                updated: udate.toLocaleDateString("en-US", options),
              },
            });
          });
          setRows(rows);
        }
      } catch (error) {
        console.log("error on listing posts", error);
      }
    };
    fetchProjects();
  }, []);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Box sx={{ display: "flex" }} className={classes.root}>
        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            height: "calc(100vh - 60px)",
          }}
        >
          <DrawerHeader />

          <Title
            name="Importer Management"
            description="Create, manage, and enable importers within the eBCD platform."
          />
          {rows !== null && (
            <Paper
              elevation={0}
              style={{ height: 400, width: "100%", flexGrow: 2 }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                  `super-app-theme--${params.getValue(
                    params.id,
                    "importerStatus"
                  )}`
                }
                rowHeight={32}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Paper>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default Importers;
