//@ts-check
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import awsconfig from "../aws-exports";
import Amplify, { API, Auth, graphqlOperation, Hub } from "aws-amplify";
import { listBcdAccounts } from "../graphql/queries";
import WebFont from "webfontloader";

import Loading from "./common/Loading";
import { ThemeProvider } from "@emotion/react";
import Dashboard from "./application/Dashboard";
import Organizations from "./application/Organizations";
import Declarations from "./application/Declarations";
import Users from "./application/Users";
import Register from "./account/Register";
import Forgot from "./account/Forgot";
import Verify from "./account/Verify";
import { UserContext } from "./context/UserContext";
import Importers from "./application/Importers";
import Suppliers from "./application/Suppliers";
import DefaultTheme from "./common/Themes";
import EditDeclaration from "./application/declarations/EditDeclaration";

const SignIn = lazy(() => import("./account/SignIn"));

Amplify.configure(awsconfig);

function App() {
  // Set user data for context
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    WebFont.load({ google: { families: ["Assistant"] } });

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });

    function getUser() {
      return Auth.currentAuthenticatedUser()
        .then((userData) => {
          getUserDB(userData).then((data) => {
            setAccount(data);
          });
          return userData;
        })
        .catch(() => console.log("Not signed in"));
    }

    getUser().then((userData) => setUser(userData));
  }, []);

  const getUserDB = async (userData) => {
    const query = {
      filter: { email: { eq: userData.attributes.email } },
      limit: 10000,
    };
    const response = await API.graphql(
      graphqlOperation(listBcdAccounts, query)
    );
    if (response.hasOwnProperty("data")) {
      const data = response["data"].listBcdAccounts.items[0];
      return data;
    }
    return null;
  };

  const HomeRedirect = () => {
    return <Redirect to="/app/home" />;
  };

  const SignInRedirect = () => {
    return <Redirect to="/signin" />;
  };

  return (
    <React.Fragment>
      <UserContext.Provider value={{ user, account }}>
        <ThemeProvider theme={DefaultTheme}>
          <Router>
            {user ? (
              <Switch>
                <Route exact path="/app/organizations">
                  <Suspense fallback={<Loading />}>
                    <Organizations />
                  </Suspense>
                </Route>
                <Route exact path="/app/users">
                  <Suspense fallback={<Loading />}>
                    <Users />
                  </Suspense>
                </Route>
                <Route exact path="/app/importers">
                  <Suspense fallback={<Loading />}>
                    <Importers />
                  </Suspense>
                </Route>
                <Route exact path="/app/suppliers">
                  <Suspense fallback={<Loading />}>
                    <Suppliers />
                  </Suspense>
                </Route>
                <Route path="/app/declarations/:id">
                  <Suspense fallback={<Loading />}>
                    <EditDeclaration />
                  </Suspense>
                </Route>
                <Route exact path="/app/declarations">
                  <Suspense fallback={<Loading />}>
                    <Declarations />
                  </Suspense>
                </Route>
                <Route exact path="/app/home">
                  <Suspense fallback={<Loading />}>
                    <Dashboard />
                  </Suspense>
                </Route>
                <Route path="/">
                  <HomeRedirect />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/verify/:email?">
                  <Suspense fallback={<Loading />}>
                    <Verify />
                  </Suspense>
                </Route>
                <Route exact path="/forgot">
                  <Suspense fallback={<Loading />}>
                    <Forgot />
                  </Suspense>
                </Route>
                <Route exact path="/register">
                  <Suspense fallback={<Loading />}>
                    <Register />
                  </Suspense>
                </Route>
                <Route exact path="/signin">
                  <Suspense fallback={<Loading />}>
                    <SignIn />
                  </Suspense>
                </Route>
                <Route path="/">
                  <SignInRedirect />
                </Route>
              </Switch>
            )}
          </Router>
        </ThemeProvider>
      </UserContext.Provider>
    </React.Fragment>
  );
}
export default App;
