//@ts-check
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import Copyright from "../common/Copyright";
import IsAlphaNumeric from "../common/IsAlphaNumeric";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Background from "../../images/Horseshoe-Bay-Beach.jpg";
import Message from "../common/Message";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import "../../styles/App.css";
import DefaultTheme, { DefaultStyles } from "../common/Themes";

const Register = () => {
  const history = useHistory();
  const classes = DefaultStyles();

  const [message, setMessage] = useState({ text: "", type: "error" });
  const [userData, setUserData] = useState({
    organization: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const gotoSignIn = (e) => {
    e.preventDefault();
    history.push("/signin");
  };

  const register = async () => {
    try {
      // Checks against first name
      if (userData.firstName.length < 1) {
        throw Object.assign(new Error("First Name is required"), {
          type: "error",
        });
      } else if (userData.firstName.length > 30) {
        throw Object.assign(
          new Error("First Name must be less than 30 characters long"),
          { type: "error" }
        );
      } else if (IsAlphaNumeric(userData.firstName) === false) {
        throw Object.assign(
          new Error("First Name can only contain alphanumeric characters"),
          { type: "error" }
        );
      } else if (userData.lastName.length < 1) {
        throw Object.assign(new Error("Last Name is required"), {
          type: "error",
        });
      } else if (userData.lastName.length > 30) {
        throw Object.assign(
          new Error("Last Name must be less than 30 characters long"),
          { type: "error" }
        );
      } else if (IsAlphaNumeric(userData.firstName) === false) {
        throw Object.assign(
          new Error("Last Name can only contain alphanumeric characters"),
          { type: "error" }
        );
      } else if (
        userData.organization === "" ||
        userData.firstName === "" ||
        userData.lastName === "" ||
        userData.email === "" ||
        userData.phone === "" ||
        userData.password === ""
      ) {
        throw Object.assign(
          new Error("Account registration fields cannot be empty"),
          { type: "error" }
        );
      } else {
        await Auth.signUp({
          username: userData.email,
          password: userData.password,
          attributes: {
            given_name: userData.firstName,
            family_name: userData.lastName,
            phone_number: userData.phone,
            "custom:organization": userData.organization,
          },
        });
        setMessage({ text: "", type: "" });
        // TODO: Change this redirect
        history.push(`/verify/${userData.email}`);
      }
    } catch (error) {
      setMessage({ text: error.message, type: "error" });
    }
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={classes.root}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Message message={message} />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Get started in minutes.
            </Typography>
            <Typography component="p" sx={{ textAlign: "center" }}>
              First, let's create your account. Once your account has been
              created within your organization, you can get started creating
              declarations.
            </Typography>
            <Box
              component="form"
              noValidate
              // onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="organization"
                    autoFocus
                    className="account-input"
                    fullWidth
                    id="account-organization"
                    inputProps={{ "aria-label": "Organization" }}
                    label="Organization"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        organization: e.target.value,
                      }))
                    }
                    required
                    type="text"
                    value={userData.organization}
                    variant="outlined"
                    helperText="Your organization will provide their code to you"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    className="account-input"
                    fullWidth
                    id="account-first-name"
                    inputProps={{ "aria-label": "First Name" }}
                    label="First Name"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        firstName: e.target.value,
                      }))
                    }
                    required
                    type="text"
                    value={userData.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="family-name"
                    className="account-input"
                    fullWidth
                    id="account-last-name"
                    inputProps={{ "aria-label": "Last Name" }}
                    label="Last Name"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        lastName: e.target.value,
                      }))
                    }
                    required
                    type="text"
                    value={userData.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="email"
                    className="account-input"
                    fullWidth
                    id="account-email"
                    inputProps={{ "aria-label": "Email Address" }}
                    label="Email Address"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    required
                    type="email"
                    value={userData.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="phone"
                    className="account-input"
                    fullWidth
                    id="account-phone"
                    inputProps={{ "aria-label": "Phone Number" }}
                    label="Phone Number"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                    placeholder="+12345678900"
                    required
                    type="tel"
                    value={userData.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="current-password"
                    className="account-input"
                    fullWidth
                    id="account-password"
                    inputProps={{ "aria-label": "Password" }}
                    label="Password"
                    margin="normal"
                    onChange={(e) =>
                      setUserData((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }))
                    }
                    required
                    type="password"
                    value={userData.password}
                    variant="outlined"
                    helperText="Password must be at least 12 character and contain uppercase and lowercase letters, special characters, and numbers"
                  />
                </Grid>
              </Grid>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <Typography variant="body2">
                    I agree to the Terms of Service and Privacy Policy
                  </Typography>
                }
              />
              <Button
                className="account-input"
                color="primary"
                fullWidth
                id="account-signin"
                aria-label="Register"
                onClick={register}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Register
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="#" variant="body2" onClick={(e) => gotoSignIn(e)}>
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
