//@ts-check
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import UserType from "../mapping/UserType";

const Title = ({ name, description }) => {
  const value = useContext(UserContext);

  return (
    <React.Fragment>
      <Box
        component="p"
        sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}
      >
        <Typography component="span" variant="body2">
          {"Welcome " +
            value.user.attributes.given_name +
            " " +
            value.user.attributes.family_name}
        </Typography>
        {value.account !== null && (
          <UserType
            code={value.account.accountType}
            status={value.account.accountStatus}
            offset={true}
          />
        )}
      </Box>
      <Typography variant="h4" component="h2">
        {name}
      </Typography>
      <Typography paragraph component="p" sx={{ pt: 2, pb: 2, color: "#666" }}>
        {description}
      </Typography>
    </React.Fragment>
  );
};

export default Title;
