/* Countries
 * Where: Anywhere where country is asked
 */

import { MenuItem, TextField } from "@mui/material";
import React from "react";

const Countries = ({ prefix, label, active, setActive }) => {
  console.log("active: ", active);
  const list = [
    { code: "AD", country: "ANDORRA" },
    { code: "AE", country: "UNITED ARAB EMIRATES" },
    { code: "AF", country: "AFGHANISTAN" },
    { code: "AG", country: "ANTIGUA AND BARBUDA" },
    { code: "AI", country: "ANGUILLA" },
    { code: "AL", country: "ALBANIA" },
    { code: "AM", country: "ARMENIA" },
    { code: "AN", country: "NETHERLANDS ANTILLES" },
    { code: "AO", country: "ANGOLA" },
    { code: "AQ", country: "ANTARCTICA" },
    { code: "AR", country: "ARGENTINA" },
    { code: "AS", country: "AMERICAN SAMOA" },
    { code: "AT", country: "AUSTRIA" },
    { code: "AU", country: "AUSTRALIA" },
    { code: "AW", country: "ARUBA" },
    { code: "AZ", country: "AZERBAIJAN" },
    { code: "BA", country: "BOSNIA AND HERZEGOWINA" },
    { code: "BB", country: "BARBADOS" },
    { code: "BD", country: "BANGLADESH" },
    { code: "BE", country: "BELGIUM" },
    { code: "BF", country: "BURKINA FASO" },
    { code: "BG", country: "BULGARIA" },
    { code: "BH", country: "BAHRAIN" },
    { code: "BI", country: "BURUNDI" },
    { code: "BJ", country: "BENIN" },
    { code: "BM", country: "BERMUDA" },
    { code: "BN", country: "BRUNEI DARUSSALAM" },
    { code: "BO", country: "BOLIVIA" },
    { code: "BR", country: "BRAZIL" },
    { code: "BS", country: "BAHAMAS" },
    { code: "BT", country: "BHUTAN" },
    { code: "BV", country: "BOUVET ISLAND" },
    { code: "BW", country: "BOTSWANA" },
    { code: "BY", country: "BELARUS" },
    { code: "BZ", country: "BELIZE" },
    { code: "CA", country: "CANADA" },
    { code: "CC", country: "COCOS (KEELING) ISLANDS" },
    { code: "CD", country: "CONGO" },
    { code: "CF", country: "CENTRAL AFRICAN REPUBLIC" },
    { code: "CG", country: "CONGO" },
    { code: "CH", country: "SWITZERLAND" },
    { code: "CI", country: "COTE D'IVOIRE" },
    { code: "CK", country: "COOK ISLANDS" },
    { code: "CL", country: "CHILE" },
    { code: "CM", country: "CAMEROON" },
    { code: "CN", country: "CHINA" },
    { code: "CO", country: "COLOMBIA" },
    { code: "CR", country: "COSTA RICA" },
    { code: "CU", country: "CUBA" },
    { code: "CV", country: "CAPE VERDE" },
    { code: "CX", country: "CHRISTMAS ISLAND" },
    { code: "CY", country: "CYPRUS" },
    { code: "CZ", country: "CZECH REPUBLIC" },
    { code: "DE", country: "GERMANY" },
    { code: "DJ", country: "DJIBOUTI" },
    { code: "DK", country: "DENMARK" },
    { code: "DM", country: "DOMINICA" },
    { code: "DO", country: "DOMINICAN REPUBLIC" },
    { code: "DZ", country: "ALGERIA" },
    { code: "EC", country: "ECUADOR" },
    { code: "EE", country: "ESTONIA" },
    { code: "EG", country: "EGYPT" },
    { code: "EH", country: "WESTERN SAHARA" },
    { code: "ER", country: "ERITREA" },
    { code: "ES", country: "SPAIN" },
    { code: "ET", country: "ETHIOPIA" },
    { code: "FI", country: "FINLAND" },
    { code: "FJ", country: "FIJI" },
    { code: "FK", country: "FALKLAND ISLANDS (MALVINAS)" },
    { code: "FM", country: "MICRONESIA" },
    { code: "FO", country: "FAROE ISLANDS" },
    { code: "FR", country: "FRANCE" },
    { code: "FX", country: "FRANCE" },
    { code: "GA", country: "GABON" },
    { code: "GB", country: "UNITED KINGDOM" },
    { code: "GD", country: "GRENADA" },
    { code: "GE", country: "GEORGIA" },
    { code: "GF", country: "FRENCH GUIANA" },
    { code: "GH", country: "GHANA" },
    { code: "GI", country: "GIBRALTAR" },
    { code: "GL", country: "GREENLAND" },
    { code: "GM", country: "GAMBIA" },
    { code: "GN", country: "GUINEA" },
    { code: "GP", country: "GUADELOUPE" },
    { code: "GQ", country: "EQUATORIAL GUINEA" },
    { code: "GR", country: "GREECE" },
    { code: "GS", country: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
    { code: "GT", country: "GUATEMALA" },
    { code: "GU", country: "GUAM" },
    { code: "GW", country: "GUINEA-BISSAU" },
    { code: "GY", country: "GUYANA" },
    { code: "HK", country: "HONG KONG" },
    { code: "HM", country: "HEARD AND MC DONALD ISLANDS" },
    { code: "HN", country: "HONDURAS" },
    { code: "HR", country: "CROATIA (LOCAL NAME HRVATSKA)" },
    { code: "HT", country: "HAITI" },
    { code: "HU", country: "HUNGARY" },
    { code: "ID", country: "INDONESIA" },
    { code: "IE", country: "IRELAND" },
    { code: "IL", country: "ISRAEL" },
    { code: "IN", country: "INDIA" },
    { code: "IO", country: "BRITISH INDIAN OCEAN TERRITORY" },
    { code: "IQ", country: "IRAQ" },
    { code: "IR", country: "IRAN (ISLAMIC REPUBLIC OF)" },
    { code: "IS", country: "ICELAND" },
    { code: "IT", country: "ITALY" },
    { code: "JM", country: "JAMAICA" },
    { code: "JO", country: "JORDAN" },
    { code: "JP", country: "JAPAN" },
    { code: "KE", country: "KENYA" },
    { code: "KG", country: "KYRGYZSTAN" },
    { code: "KH", country: "CAMBODIA" },
    { code: "KI", country: "KIRIBATI" },
    { code: "KM", country: "COMOROS" },
    { code: "KN", country: "SAINT KITTS AND NEVIS" },
    { code: "KP", country: "KOREA" },
    { code: "KR", country: "KOREA" },
    { code: "KW", country: "KUWAIT" },
    { code: "KY", country: "CAYMAN ISLANDS" },
    { code: "KZ", country: "KAZAKHSTAN" },
    { code: "LA", country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
    { code: "LB", country: "LEBANON" },
    { code: "LC", country: "SAINT LUCIA" },
    { code: "LI", country: "LIECHTENSTEIN" },
    { code: "LK", country: "SRI LANKA" },
    { code: "LR", country: "LIBERIA" },
    { code: "LS", country: "LESOTHO" },
    { code: "LT", country: "LITHUANIA" },
    { code: "LU", country: "LUXEMBOURG" },
    { code: "LV", country: "LATVIA" },
    { code: "LY", country: "LIBYAN ARAB JAMAHIRIYA" },
    { code: "MA", country: "MOROCCO" },
    { code: "MC", country: "MONACO" },
    { code: "MD", country: "MOLDOVA" },
    { code: "MG", country: "MADAGASCAR" },
    { code: "MH", country: "MARSHALL ISLANDS" },
    { code: "MK", country: "MACEDONIA" },
    { code: "ML", country: "MALI" },
    { code: "MM", country: "MYANMAR" },
    { code: "MN", country: "MONGOLIA" },
    { code: "MO", country: "MACAU" },
    { code: "MP", country: "NORTHERN MARIANA ISLANDS" },
    { code: "MQ", country: "MARTINIQUE" },
    { code: "MR", country: "MAURITANIA" },
    { code: "MS", country: "MONTSERRAT" },
    { code: "MT", country: "MALTA" },
    { code: "MU", country: "MAURITIUS" },
    { code: "MV", country: "MALDIVES" },
    { code: "MW", country: "MALAWI" },
    { code: "MX", country: "MEXICO" },
    { code: "MY", country: "MALAYSIA" },
    { code: "MZ", country: "MOZAMBIQUE" },
    { code: "NA", country: "NAMIBIA" },
    { code: "NC", country: "NEW CALEDONIA" },
    { code: "NE", country: "NIGER" },
    { code: "NF", country: "NORFOLK ISLAND" },
    { code: "NG", country: "NIGERIA" },
    { code: "NI", country: "NICARAGUA" },
    { code: "NL", country: "NETHERLANDS" },
    { code: "NO", country: "NORWAY" },
    { code: "NP", country: "NEPAL" },
    { code: "NR", country: "NAURU" },
    { code: "NU", country: "NIUE" },
    { code: "NZ", country: "NEW ZEALAND" },
    { code: "OM", country: "OMAN" },
    { code: "PA", country: "PANAMA" },
    { code: "PE", country: "PERU" },
    { code: "PF", country: "FRENCH POLYNESIA" },
    { code: "PG", country: "PAPUA NEW GUINEA" },
    { code: "PH", country: "PHILIPPINES" },
    { code: "PK", country: "PAKISTAN" },
    { code: "PL", country: "POLAND" },
    { code: "PM", country: "ST. PIERRE AND MIQUELON" },
    { code: "PN", country: "PITCAIRN" },
    { code: "PR", country: "PUERTO RICO" },
    { code: "PS", country: "PALESTINIAN TERRITORY" },
    { code: "PT", country: "PORTUGAL" },
    { code: "PW", country: "PALAU" },
    { code: "PY", country: "PARAGUAY" },
    { code: "QA", country: "QATAR" },
    { code: "RE", country: "REUNION" },
    { code: "RO", country: "ROMANIA" },
    { code: "RU", country: "RUSSIAN FEDERATION" },
    { code: "RW", country: "RWANDA" },
    { code: "SA", country: "SAUDI ARABIA" },
    { code: "SB", country: "SOLOMON ISLANDS" },
    { code: "SC", country: "SEYCHELLES" },
    { code: "SD", country: "SUDAN" },
    { code: "SE", country: "SWEDEN" },
    { code: "SG", country: "SINGAPORE" },
    { code: "SH", country: "ST. HELENA" },
    { code: "SI", country: "SLOVENIA" },
    { code: "SJ", country: "SVALBARD AND JAN MAYEN ISLANDS" },
    { code: "SK", country: "SLOVAKIA (Slovak Republic)" },
    { code: "SL", country: "SIERRA LEONE" },
    { code: "SM", country: "SAN MARINO" },
    { code: "SN", country: "SENEGAL" },
    { code: "SO", country: "SOMALIA" },
    { code: "SR", country: "SURINAME" },
    { code: "ST", country: "SAO TOME AND PRINCIPE" },
    { code: "SV", country: "EL SALVADOR" },
    { code: "SY", country: "SYRIAN ARAB REPUBLIC" },
    { code: "SZ", country: "SWAZILAND" },
    { code: "TC", country: "TURKS AND CAICOS ISLANDS" },
    { code: "TD", country: "CHAD" },
    { code: "TF", country: "FRENCH SOUTHERN TERRITORIES" },
    { code: "TG", country: "TOGO" },
    { code: "TH", country: "THAILAND" },
    { code: "TJ", country: "TAJIKISTAN" },
    { code: "TK", country: "TOKELAU" },
    { code: "TM", country: "TURKMENISTAN" },
    { code: "TN", country: "TUNISIA" },
    { code: "TO", country: "TONGA" },
    { code: "TP", country: "EAST TIMOR" },
    { code: "TR", country: "TURKEY" },
    { code: "TT", country: "TRINIDAD AND TOBAGO" },
    { code: "TV", country: "TUVALU" },
    { code: "TW", country: "TAIWAN" },
    { code: "TZ", country: "TANZANIA" },
    { code: "UA", country: "UKRAINE" },
    { code: "UG", country: "UGANDA" },
    { code: "UM", country: "UNITED STATES MINOR OUTLYING ISLANDS" },
    { code: "US", country: "UNITED STATES" },
    { code: "UY", country: "URUGUAY" },
    { code: "UZ", country: "UZBEKISTAN" },
    { code: "VA", country: "HOLY SEE (VATICAN CITY STATE)" },
    { code: "VC", country: "SAINT VINCENT AND THE GRENADINES" },
    { code: "VE", country: "VENEZUELA" },
    { code: "VG", country: "VIRGIN ISLANDS (BRITISH)" },
    { code: "VI", country: "VIRGIN ISLANDS (U.S.)" },
    { code: "VN", country: "VIET NAM" },
    { code: "VU", country: "VANUATU" },
    { code: "WF", country: "WALLIS AND FUTUNA ISLANDS" },
    { code: "WS", country: "SAMOA" },
    { code: "YE", country: "YEMEN" },
    { code: "YT", country: "MAYOTTE" },
    { code: "YU", country: "YUGOSLAVIA" },
    { code: "ZA", country: "SOUTH AFRICA" },
    { code: "ZM", country: "ZAMBIA" },
  ];
  return (
    <TextField
      id={prefix + "-" + label}
      select
      fullWidth
      label={label}
      margin="normal"
      InputProps={{
        // readOnly: true,
        "aria-label": { label },
      }}
      value={active}
      onChange={setActive}
    >
      {list.map((option) => (
        <MenuItem key={option.code} value={option.code}>
          {option.country}
        </MenuItem>
      ))}
    </TextField>
  );
};
export default Countries;
