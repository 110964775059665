//@ts-check
import React from "react";

const Message = ({ message }) => {
  return (
    <>
      {message.text !== "" ? (
        <div className={message.type}>{message.text}</div>
      ) : null}
    </>
  );
};

export default Message;
