import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const DefaultTheme = createTheme({
  typography: {
    fontFamily: ["Assistant", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  palette: {
    inactive: {
      main: "#eee",
      contrastText: "#ccc!important",
    },
  },
});

export const DefaultStyles = makeStyles(() => {
  return {
    root: {
      "& .super-app-theme--0": {
        backgroundColor: "#f5f5f5",

        "&:hover": {
          backgroundColor: "#eee",
        },

        "& .MuiDataGrid-cell ": {
          color: "#bbb",
          fontStyle: "italic",

          "& a": {
            color: "#bbb",
          },
        },
      },
    },
  };
});

export default DefaultTheme;
