//@ts-check
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import { API, Auth, graphqlOperation } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
// import { createAccount } from "../../graphql/mutations";

import Copyright from "../common/Copyright";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Background from "../../images/Horseshoe-Bay-Beach.jpg";
// import Message from "../common/Message";

import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import "../../styles/App.css";
import DefaultTheme, { DefaultStyles } from "../common/Themes";

const Forgot = () => {
  const history = useHistory();
  const classes = DefaultStyles();

  // const params = useParams();

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
    newPassword: "",
    code: "",
  });
  // const [message, setMessage] = useState({ text: "", type: "error" });

  const gotoSignIn = (e) => {
    e.preventDefault();
    history.push("/signin");
  };

  const forgot = async () => {};

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={classes.root}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Message message={message} /> */}
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recover Password
            </Typography>
            <Typography component="p" sx={{ textAlign: "center" }}>
              Don't worry. It happens to the best of us.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                autoComplete="email"
                autoFocus
                className="account-input"
                fullWidth
                id="account-email"
                inputProps={{ "aria-label": "Email Address" }}
                label="Email Address"
                margin="normal"
                onChange={(e) =>
                  setSignInData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                required
                type="text"
                value={signInData.email}
                variant="outlined"
              />
              <Button
                className="account-input"
                color="primary"
                fullWidth
                id="account-recover"
                aria-label="Email me a Reocery Link"
                onClick={forgot}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Email me a Recovery Link
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    href="/signin"
                    variant="body2"
                    onClick={(e) => gotoSignIn(e)}
                  >
                    {"Cancel and return to sign in"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Forgot;
