//@ts-check
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";

import Copyright from "../common/Copyright";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Background from "../../images/Horseshoe-Bay-Beach.jpg";
import Message from "../common/Message";

import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import "../../styles/App.css";
import DefaultTheme, { DefaultStyles } from "../common/Themes";

const Verify = () => {
  const history = useHistory();
  const classes = DefaultStyles();
  const params = useParams();

  React.useEffect(() => {
    if ("email" in params) {
      setUserData((prevState) => ({
        ...prevState,
        email: params["email"],
      }));
    }
  }, [params]);

  const [userData, setUserData] = useState({ email: "", token: "" });
  const [message, setMessage] = useState({ text: "", type: "error" });

  const verification = async () => {
    try {
      if (userData.token === "") {
        throw Object.assign(new Error("Verification code cannot be empty"), {
          type: "error",
        });
      } else if (userData.email === "") {
        throw Object.assign(new Error("Username cannot be empty"), {
          type: "error",
        });
      }

      await Auth.confirmSignUp(userData.email, userData.token);
      setMessage({ text: "", type: "" });
      history.push("/signin/verified");
    } catch (error) {
      if (error.code !== "") {
        setMessage({ text: error.message, type: "error" });
      }
    }
  };

  const resendVerification = async (e) => {
    e.preventDefault();
    try {
      await Auth.resendSignUp(userData.email);
      setMessage({
        text: "Successful resent yur verification code",
        type: "info",
      });
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className={classes.root}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Message message={message} />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Verify Email Address
            </Typography>
            <Typography component="p" sx={{ textAlign: "center" }}>
              We sent you an email with a code in it to confirm that you are who
              you are.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                autoComplete="email"
                autoFocus
                className="account-input"
                fullWidth
                id="account-email"
                inputProps={{ "aria-label": "Email Address" }}
                label="Email Address"
                margin="normal"
                onChange={(e) =>
                  setUserData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                required
                type="text"
                value={userData.email}
                variant="outlined"
              />
              <TextField
                autoComplete="off"
                autoFocus
                className="account-input"
                fullWidth
                id="account-token"
                inputProps={{ "aria-label": "Verification Code" }}
                label="Verification Code"
                margin="normal"
                onChange={(e) =>
                  setUserData((prevState) => ({
                    ...prevState,
                    token: e.target.value,
                  }))
                }
                required
                type="text"
                value={userData.token}
                variant="outlined"
              />
              <Button
                className="account-input"
                color="primary"
                fullWidth
                id="account-validate"
                aria-label="Verify"
                onClick={verification}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Verify
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    href="/verify"
                    variant="body2"
                    onClick={(e) => resendVerification(e)}
                  >
                    {"Missing the verification code? Resend"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Verify;
