import React, { useEffect, useState } from "react";

import Header, { DrawerHeader } from "./template/Header";
import Status from "./mapping/Status";
import Title from "./template/Title";

import { API, graphqlOperation } from "aws-amplify";
import { listBcdDeclarations } from "../../graphql/queries";

import { ThemeProvider } from "@mui/material/styles";
import DefaultTheme, { DefaultStyles } from "../common/Themes";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Info, Pageview } from "@mui/icons-material";
import {
  ConvertDeclarationType,
  DeclarationIcon,
} from "./mapping/DeclarationType";
import { useHistory } from "react-router";

const Declarations = () => {
  const classes = DefaultStyles();
  const history = useHistory();

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    { field: "updatedAt", sort: "desc" },
  ]);

  const columns = [
    {
      align: "center",
      field: "declarationStatus",
      headerAlign: "center",
      headerName: "Status",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <Status code={params.row.declarationStatus} />;
      },
    },
    {
      field: "id",
      headerName: "Declaration ID",
      hide: true,
      disableColumnMenu: true,
      sortable: false,
      width: 300,
    },
    { field: "organization", headerName: "Organization", width: 140 },
    { field: "supplier", headerName: "Supplier", width: 120 },
    {
      field: "declarationType",
      align: "center",
      headerAlign: "center",
      headerName: "Type",
      width: 100,
      renderCell: (params) => <DeclarationIcon params={params} />,
    },
    { field: "importer", headerName: "Importer", width: 120 },
    { field: "transportID", headerName: "Transport", width: 120 },
    {
      field: "user",
      headerName: "Creator",
      width: 120,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.action.accountID} arrow>
            <Typography
              component="span"
              variant="body2"
              sx={{ cursor: "help" }}
            >
              {params.row.creator}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "recordCount",
      align: "center",
      headerAlign: "center",
      headerName: "Total Records",
      width: 120,
    },
    {
      field: "createdAt",
      headerName: "Created",
      width: 120,
      renderCell: (params) => {
        return params.row.action.created;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      width: 120,
      renderCell: (params) => {
        return params.row.action.updated;
      },
    },
    {
      align: "center",
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <React.Fragment>
            <IconButton
              onClick={(e) => alert(JSON.stringify(params.row.action.id))}
            >
              <Info />
            </IconButton>
            <IconButton
              onClick={(e) =>
                history.push("/app/declarations/" + params.row.action.id)
              }
            >
              <Pageview />
            </IconButton>
          </React.Fragment>
        );
      },
    },
  ];
  // const rows = [{ id: 1, firstName: "Bill" }];
  const [rows, setRows] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        let data = null;
        const query = { limit: 10000 };
        const response = await API.graphql(
          graphqlOperation(listBcdDeclarations, query)
        );

        // Check if query returned expected data
        if (response.hasOwnProperty("data")) {
          data = response["data"].listBcdDeclarations.items;
          data.sort((a, b) => {
            if (a.lastName < b.lastName) return -1;
            return a.lastName > b.lastName ? 1 : 0;
          });
          console.log(data);

          const rows = [];
          data.forEach((u, idx) => {
            var options = {
              year: "numeric",
              month: "short",
              day: "numeric",
            };
            const cdate = new Date(u.createdAt);
            const udate = new Date(u.updatedAt);

            rows.push({
              // id: idx + 1,
              id: u.id,
              declarationStatus: u.declarationStatus,
              declarationType: ConvertDeclarationType(u.declarationType),
              organization: u.BcdAccount.orgName,
              supplier: u.BcdSupplier.supplierName,
              importer: u.BcdImporter.importerName,
              transportID: u.BcdTransport.transportID,
              creator: u.BcdAccount.firstName + " " + u.BcdAccount.lastName,
              recordCount: u.BcdRecords.items.length,
              createdAt: u.createdAt,
              updatedAt: u.updatedAt,
              action: {
                id: u.id,
                accountID: u.BcdAccount.id,
                fullview: u,
                created: cdate.toLocaleDateString("en-US", options),
                updated: udate.toLocaleDateString("en-US", options),
              },
            });
          });
          setRows(rows);
        }
      } catch (error) {
        console.log("error on listing posts", error);
      }
    };
    fetchProjects();
  }, []);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Box sx={{ display: "flex" }} className={classes.root}>
        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            height: "calc(100vh - 60px)",
          }}
        >
          <DrawerHeader />

          <Title
            name="Declaration Management"
            description="Create, manage, and approve declarations within the eBCD platform."
          />
          {rows !== null && (
            <Paper
              elevation={0}
              style={{ height: 400, width: "100%", flexGrow: 2 }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                  `super-app-theme--${params.getValue(
                    params.id,
                    "declarationStatus"
                  )}`
                }
                rowHeight={32}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </Paper>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default Declarations;
