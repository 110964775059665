//@ts-check
import React from "react";
import { Typography } from "@mui/material";
import { Copyright as Copy } from "@mui/icons-material";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 6 }}
    >
      {"Copyright"}
      <Copy
        sx={{
          verticalAlign: "text-top",
          fontSize: "1rem",
          mr: 0.5,
          ml: 0.5,
        }}
      />
      {"Site Schema, LLC "}
      {/* {new Date().getFullYear()} */}
    </Typography>
  );
}

export default Copyright;
