//@ts-check
import React from "react";
import Header, { DrawerHeader } from "./template/Header";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import Title from "./template/Title";
import DefaultTheme, { DefaultStyles } from "../common/Themes";

const Dashboard = () => {
  const classes = DefaultStyles();

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Box sx={{ display: "flex" }} className={classes.root}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          <Title
            name="Dashboard"
            description="Welcome to the eBCD Dashbord where you can manage all aspects of filing customs declarations for Bermuda"
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default Dashboard;
