import { TextField } from "@mui/material";
import React from "react";

const BcdTextfield = ({ prefix, label, value }) => {
  return (
    <TextField
      autoComplete="off"
      className="declaration-input"
      fullWidth
      id={prefix + "-" + label}
      InputProps={{
        readOnly: true,
        "aria-label": { label },
      }}
      label={label}
      margin="normal"
      required
      type="text"
      value={value}
      variant="outlined"
    />
  );
};

export default BcdTextfield;
