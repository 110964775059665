//@ts-check
import React from "react";
import { Box } from "@mui/material";

const Loading = () => {
  return (
    <Box className="loading mr-a ml-a">
      <img
        alt="Loading..."
        className="loading-icon"
        src="/images/loader.svg"
        width="2rem"
        height="2rem"
      />
    </Box>
  );
};
export default Loading;
