/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBcdSupplier = /* GraphQL */ `
  query GetBcdSupplier($id: ID!) {
    getBcdSupplier(id: $id) {
      id
      supplierName
      supplierAddress
      supplierCity
      supplierState
      supplierZip
      supplierCountry
      supplierStatus
      bcdorganizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBcdSuppliers = /* GraphQL */ `
  query ListBcdSuppliers(
    $filter: ModelBcdSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        supplierName
        supplierAddress
        supplierCity
        supplierState
        supplierZip
        supplierCountry
        supplierStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdSuppliers = /* GraphQL */ `
  query SyncBcdSuppliers(
    $filter: ModelBcdSupplierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdSuppliers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        supplierName
        supplierAddress
        supplierCity
        supplierState
        supplierZip
        supplierCountry
        supplierStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdImporter = /* GraphQL */ `
  query GetBcdImporter($id: ID!) {
    getBcdImporter(id: $id) {
      id
      customsRef
      importerName
      importerAddress
      importerCity
      importerState
      importerZip
      importerCountry
      importerStatus
      bcdorganizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBcdImporters = /* GraphQL */ `
  query ListBcdImporters(
    $filter: ModelBcdImporterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdImporters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customsRef
        importerName
        importerAddress
        importerCity
        importerState
        importerZip
        importerCountry
        importerStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdImporters = /* GraphQL */ `
  query SyncBcdImporters(
    $filter: ModelBcdImporterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdImporters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customsRef
        importerName
        importerAddress
        importerCity
        importerState
        importerZip
        importerCountry
        importerStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdTransport = /* GraphQL */ `
  query GetBcdTransport($id: ID!) {
    getBcdTransport(id: $id) {
      id
      transportID
      port
      arrivalDate
      manifestID
      masterBOL
      houseBOL
      containerID
      countryOfDirect
      countryOfOrigin
      warehouseID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBcdTransports = /* GraphQL */ `
  query ListBcdTransports(
    $filter: ModelBcdTransportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdTransports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transportID
        port
        arrivalDate
        manifestID
        masterBOL
        houseBOL
        containerID
        countryOfDirect
        countryOfOrigin
        warehouseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdTransports = /* GraphQL */ `
  query SyncBcdTransports(
    $filter: ModelBcdTransportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdTransports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transportID
        port
        arrivalDate
        manifestID
        masterBOL
        houseBOL
        containerID
        countryOfDirect
        countryOfOrigin
        warehouseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdRecord = /* GraphQL */ `
  query GetBcdRecord($id: ID!) {
    getBcdRecord(id: $id) {
      id
      recordNum
      description
      value
      exchangeRate
      quantity
      bcddeclarationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBcdRecords = /* GraphQL */ `
  query ListBcdRecords(
    $filter: ModelBcdRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordNum
        description
        value
        exchangeRate
        quantity
        bcddeclarationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdRecords = /* GraphQL */ `
  query SyncBcdRecords(
    $filter: ModelBcdRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        recordNum
        description
        value
        exchangeRate
        quantity
        bcddeclarationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdDeclaration = /* GraphQL */ `
  query GetBcdDeclaration($id: ID!) {
    getBcdDeclaration(id: $id) {
      id
      declarationStatus
      declarationType
      declarationSubType
      traderReference
      bcdaccountID
      bcdorganizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BcdSupplier {
        id
        supplierName
        supplierAddress
        supplierCity
        supplierState
        supplierZip
        supplierCountry
        supplierStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BcdImporter {
        id
        customsRef
        importerName
        importerAddress
        importerCity
        importerState
        importerZip
        importerCountry
        importerStatus
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BcdTransport {
        id
        transportID
        port
        arrivalDate
        manifestID
        masterBOL
        houseBOL
        containerID
        countryOfDirect
        countryOfOrigin
        warehouseID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      BcdRecords {
        items {
          id
          recordNum
          description
          value
          exchangeRate
          quantity
          bcddeclarationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      BcdAccount {
        id
        email
        firstName
        lastName
        phone
        accountStatus
        accountType
        orgName
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdDeclarations {
          items {
            id
            declarationStatus
            declarationType
            declarationSubType
            traderReference
            bcdaccountID
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdSupplier {
              id
              supplierName
              supplierAddress
              supplierCity
              supplierState
              supplierZip
              supplierCountry
              supplierStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdImporter {
              id
              customsRef
              importerName
              importerAddress
              importerCity
              importerState
              importerZip
              importerCountry
              importerStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdTransport {
              id
              transportID
              port
              arrivalDate
              manifestID
              masterBOL
              houseBOL
              containerID
              countryOfDirect
              countryOfOrigin
              warehouseID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdRecords {
              items {
                id
                recordNum
                description
                value
                exchangeRate
                quantity
                bcddeclarationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            BcdAccount {
              id
              email
              firstName
              lastName
              phone
              accountStatus
              accountType
              orgName
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdDeclarations {
                items {
                  id
                  declarationStatus
                  declarationType
                  declarationSubType
                  traderReference
                  bcdaccountID
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdSupplier {
                    id
                    supplierName
                    supplierAddress
                    supplierCity
                    supplierState
                    supplierZip
                    supplierCountry
                    supplierStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdImporter {
                    id
                    customsRef
                    importerName
                    importerAddress
                    importerCity
                    importerState
                    importerZip
                    importerCountry
                    importerStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdTransport {
                    id
                    transportID
                    port
                    arrivalDate
                    manifestID
                    masterBOL
                    houseBOL
                    containerID
                    countryOfDirect
                    countryOfOrigin
                    warehouseID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdRecords {
                    nextToken
                    startedAt
                  }
                  BcdAccount {
                    id
                    email
                    firstName
                    lastName
                    phone
                    accountStatus
                    accountType
                    orgName
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listBcdDeclarations = /* GraphQL */ `
  query ListBcdDeclarations(
    $filter: ModelBcdDeclarationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdDeclarations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        declarationStatus
        declarationType
        declarationSubType
        traderReference
        bcdaccountID
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdSupplier {
          id
          supplierName
          supplierAddress
          supplierCity
          supplierState
          supplierZip
          supplierCountry
          supplierStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdImporter {
          id
          customsRef
          importerName
          importerAddress
          importerCity
          importerState
          importerZip
          importerCountry
          importerStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdTransport {
          id
          transportID
          port
          arrivalDate
          manifestID
          masterBOL
          houseBOL
          containerID
          countryOfDirect
          countryOfOrigin
          warehouseID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdRecords {
          items {
            id
            recordNum
            description
            value
            exchangeRate
            quantity
            bcddeclarationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdAccount {
          id
          email
          firstName
          lastName
          phone
          accountStatus
          accountType
          orgName
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          BcdDeclarations {
            items {
              id
              declarationStatus
              declarationType
              declarationSubType
              traderReference
              bcdaccountID
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdSupplier {
                id
                supplierName
                supplierAddress
                supplierCity
                supplierState
                supplierZip
                supplierCountry
                supplierStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdImporter {
                id
                customsRef
                importerName
                importerAddress
                importerCity
                importerState
                importerZip
                importerCountry
                importerStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdTransport {
                id
                transportID
                port
                arrivalDate
                manifestID
                masterBOL
                houseBOL
                containerID
                countryOfDirect
                countryOfOrigin
                warehouseID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdRecords {
                items {
                  id
                  recordNum
                  description
                  value
                  exchangeRate
                  quantity
                  bcddeclarationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              BcdAccount {
                id
                email
                firstName
                lastName
                phone
                accountStatus
                accountType
                orgName
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdDeclarations {
                  items {
                    id
                    declarationStatus
                    declarationType
                    declarationSubType
                    traderReference
                    bcdaccountID
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdDeclarations = /* GraphQL */ `
  query SyncBcdDeclarations(
    $filter: ModelBcdDeclarationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdDeclarations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        declarationStatus
        declarationType
        declarationSubType
        traderReference
        bcdaccountID
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdSupplier {
          id
          supplierName
          supplierAddress
          supplierCity
          supplierState
          supplierZip
          supplierCountry
          supplierStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdImporter {
          id
          customsRef
          importerName
          importerAddress
          importerCity
          importerState
          importerZip
          importerCountry
          importerStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdTransport {
          id
          transportID
          port
          arrivalDate
          manifestID
          masterBOL
          houseBOL
          containerID
          countryOfDirect
          countryOfOrigin
          warehouseID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        BcdRecords {
          items {
            id
            recordNum
            description
            value
            exchangeRate
            quantity
            bcddeclarationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdAccount {
          id
          email
          firstName
          lastName
          phone
          accountStatus
          accountType
          orgName
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          BcdDeclarations {
            items {
              id
              declarationStatus
              declarationType
              declarationSubType
              traderReference
              bcdaccountID
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdSupplier {
                id
                supplierName
                supplierAddress
                supplierCity
                supplierState
                supplierZip
                supplierCountry
                supplierStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdImporter {
                id
                customsRef
                importerName
                importerAddress
                importerCity
                importerState
                importerZip
                importerCountry
                importerStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdTransport {
                id
                transportID
                port
                arrivalDate
                manifestID
                masterBOL
                houseBOL
                containerID
                countryOfDirect
                countryOfOrigin
                warehouseID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdRecords {
                items {
                  id
                  recordNum
                  description
                  value
                  exchangeRate
                  quantity
                  bcddeclarationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              BcdAccount {
                id
                email
                firstName
                lastName
                phone
                accountStatus
                accountType
                orgName
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdDeclarations {
                  items {
                    id
                    declarationStatus
                    declarationType
                    declarationSubType
                    traderReference
                    bcdaccountID
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdOrganization = /* GraphQL */ `
  query GetBcdOrganization($id: ID!) {
    getBcdOrganization(id: $id) {
      id
      orgName
      orgCode
      orgStatus
      orgType
      orgAddress
      orgCity
      orgState
      orgZip
      orgCountry
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BcdSuppliers {
        items {
          id
          supplierName
          supplierAddress
          supplierCity
          supplierState
          supplierZip
          supplierCountry
          supplierStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      BcdImporters {
        items {
          id
          customsRef
          importerName
          importerAddress
          importerCity
          importerState
          importerZip
          importerCountry
          importerStatus
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      BcdDeclarations {
        items {
          id
          declarationStatus
          declarationType
          declarationSubType
          traderReference
          bcdaccountID
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          BcdSupplier {
            id
            supplierName
            supplierAddress
            supplierCity
            supplierState
            supplierZip
            supplierCountry
            supplierStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdImporter {
            id
            customsRef
            importerName
            importerAddress
            importerCity
            importerState
            importerZip
            importerCountry
            importerStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdTransport {
            id
            transportID
            port
            arrivalDate
            manifestID
            masterBOL
            houseBOL
            containerID
            countryOfDirect
            countryOfOrigin
            warehouseID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdRecords {
            items {
              id
              recordNum
              description
              value
              exchangeRate
              quantity
              bcddeclarationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          BcdAccount {
            id
            email
            firstName
            lastName
            phone
            accountStatus
            accountType
            orgName
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdDeclarations {
              items {
                id
                declarationStatus
                declarationType
                declarationSubType
                traderReference
                bcdaccountID
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdSupplier {
                  id
                  supplierName
                  supplierAddress
                  supplierCity
                  supplierState
                  supplierZip
                  supplierCountry
                  supplierStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdImporter {
                  id
                  customsRef
                  importerName
                  importerAddress
                  importerCity
                  importerState
                  importerZip
                  importerCountry
                  importerStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdTransport {
                  id
                  transportID
                  port
                  arrivalDate
                  manifestID
                  masterBOL
                  houseBOL
                  containerID
                  countryOfDirect
                  countryOfOrigin
                  warehouseID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdRecords {
                  items {
                    id
                    recordNum
                    description
                    value
                    exchangeRate
                    quantity
                    bcddeclarationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
                BcdAccount {
                  id
                  email
                  firstName
                  lastName
                  phone
                  accountStatus
                  accountType
                  orgName
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdDeclarations {
                    nextToken
                    startedAt
                  }
                }
              }
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      BcdAccounts {
        items {
          id
          email
          firstName
          lastName
          phone
          accountStatus
          accountType
          orgName
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          BcdDeclarations {
            items {
              id
              declarationStatus
              declarationType
              declarationSubType
              traderReference
              bcdaccountID
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdSupplier {
                id
                supplierName
                supplierAddress
                supplierCity
                supplierState
                supplierZip
                supplierCountry
                supplierStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdImporter {
                id
                customsRef
                importerName
                importerAddress
                importerCity
                importerState
                importerZip
                importerCountry
                importerStatus
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdTransport {
                id
                transportID
                port
                arrivalDate
                manifestID
                masterBOL
                houseBOL
                containerID
                countryOfDirect
                countryOfOrigin
                warehouseID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              BcdRecords {
                items {
                  id
                  recordNum
                  description
                  value
                  exchangeRate
                  quantity
                  bcddeclarationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                nextToken
                startedAt
              }
              BcdAccount {
                id
                email
                firstName
                lastName
                phone
                accountStatus
                accountType
                orgName
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdDeclarations {
                  items {
                    id
                    declarationStatus
                    declarationType
                    declarationSubType
                    traderReference
                    bcdaccountID
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listBcdOrganizations = /* GraphQL */ `
  query ListBcdOrganizations(
    $filter: ModelBcdOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgName
        orgCode
        orgStatus
        orgType
        orgAddress
        orgCity
        orgState
        orgZip
        orgCountry
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdSuppliers {
          items {
            id
            supplierName
            supplierAddress
            supplierCity
            supplierState
            supplierZip
            supplierCountry
            supplierStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdImporters {
          items {
            id
            customsRef
            importerName
            importerAddress
            importerCity
            importerState
            importerZip
            importerCountry
            importerStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdDeclarations {
          items {
            id
            declarationStatus
            declarationType
            declarationSubType
            traderReference
            bcdaccountID
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdSupplier {
              id
              supplierName
              supplierAddress
              supplierCity
              supplierState
              supplierZip
              supplierCountry
              supplierStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdImporter {
              id
              customsRef
              importerName
              importerAddress
              importerCity
              importerState
              importerZip
              importerCountry
              importerStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdTransport {
              id
              transportID
              port
              arrivalDate
              manifestID
              masterBOL
              houseBOL
              containerID
              countryOfDirect
              countryOfOrigin
              warehouseID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdRecords {
              items {
                id
                recordNum
                description
                value
                exchangeRate
                quantity
                bcddeclarationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            BcdAccount {
              id
              email
              firstName
              lastName
              phone
              accountStatus
              accountType
              orgName
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdDeclarations {
                items {
                  id
                  declarationStatus
                  declarationType
                  declarationSubType
                  traderReference
                  bcdaccountID
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdSupplier {
                    id
                    supplierName
                    supplierAddress
                    supplierCity
                    supplierState
                    supplierZip
                    supplierCountry
                    supplierStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdImporter {
                    id
                    customsRef
                    importerName
                    importerAddress
                    importerCity
                    importerState
                    importerZip
                    importerCountry
                    importerStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdTransport {
                    id
                    transportID
                    port
                    arrivalDate
                    manifestID
                    masterBOL
                    houseBOL
                    containerID
                    countryOfDirect
                    countryOfOrigin
                    warehouseID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdRecords {
                    nextToken
                    startedAt
                  }
                  BcdAccount {
                    id
                    email
                    firstName
                    lastName
                    phone
                    accountStatus
                    accountType
                    orgName
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
        BcdAccounts {
          items {
            id
            email
            firstName
            lastName
            phone
            accountStatus
            accountType
            orgName
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdDeclarations {
              items {
                id
                declarationStatus
                declarationType
                declarationSubType
                traderReference
                bcdaccountID
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdSupplier {
                  id
                  supplierName
                  supplierAddress
                  supplierCity
                  supplierState
                  supplierZip
                  supplierCountry
                  supplierStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdImporter {
                  id
                  customsRef
                  importerName
                  importerAddress
                  importerCity
                  importerState
                  importerZip
                  importerCountry
                  importerStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdTransport {
                  id
                  transportID
                  port
                  arrivalDate
                  manifestID
                  masterBOL
                  houseBOL
                  containerID
                  countryOfDirect
                  countryOfOrigin
                  warehouseID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdRecords {
                  items {
                    id
                    recordNum
                    description
                    value
                    exchangeRate
                    quantity
                    bcddeclarationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
                BcdAccount {
                  id
                  email
                  firstName
                  lastName
                  phone
                  accountStatus
                  accountType
                  orgName
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdDeclarations {
                    nextToken
                    startedAt
                  }
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdOrganizations = /* GraphQL */ `
  query SyncBcdOrganizations(
    $filter: ModelBcdOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orgName
        orgCode
        orgStatus
        orgType
        orgAddress
        orgCity
        orgState
        orgZip
        orgCountry
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdSuppliers {
          items {
            id
            supplierName
            supplierAddress
            supplierCity
            supplierState
            supplierZip
            supplierCountry
            supplierStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdImporters {
          items {
            id
            customsRef
            importerName
            importerAddress
            importerCity
            importerState
            importerZip
            importerCountry
            importerStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        BcdDeclarations {
          items {
            id
            declarationStatus
            declarationType
            declarationSubType
            traderReference
            bcdaccountID
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdSupplier {
              id
              supplierName
              supplierAddress
              supplierCity
              supplierState
              supplierZip
              supplierCountry
              supplierStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdImporter {
              id
              customsRef
              importerName
              importerAddress
              importerCity
              importerState
              importerZip
              importerCountry
              importerStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdTransport {
              id
              transportID
              port
              arrivalDate
              manifestID
              masterBOL
              houseBOL
              containerID
              countryOfDirect
              countryOfOrigin
              warehouseID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdRecords {
              items {
                id
                recordNum
                description
                value
                exchangeRate
                quantity
                bcddeclarationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            BcdAccount {
              id
              email
              firstName
              lastName
              phone
              accountStatus
              accountType
              orgName
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdDeclarations {
                items {
                  id
                  declarationStatus
                  declarationType
                  declarationSubType
                  traderReference
                  bcdaccountID
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdSupplier {
                    id
                    supplierName
                    supplierAddress
                    supplierCity
                    supplierState
                    supplierZip
                    supplierCountry
                    supplierStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdImporter {
                    id
                    customsRef
                    importerName
                    importerAddress
                    importerCity
                    importerState
                    importerZip
                    importerCountry
                    importerStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdTransport {
                    id
                    transportID
                    port
                    arrivalDate
                    manifestID
                    masterBOL
                    houseBOL
                    containerID
                    countryOfDirect
                    countryOfOrigin
                    warehouseID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdRecords {
                    nextToken
                    startedAt
                  }
                  BcdAccount {
                    id
                    email
                    firstName
                    lastName
                    phone
                    accountStatus
                    accountType
                    orgName
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
        BcdAccounts {
          items {
            id
            email
            firstName
            lastName
            phone
            accountStatus
            accountType
            orgName
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdDeclarations {
              items {
                id
                declarationStatus
                declarationType
                declarationSubType
                traderReference
                bcdaccountID
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdSupplier {
                  id
                  supplierName
                  supplierAddress
                  supplierCity
                  supplierState
                  supplierZip
                  supplierCountry
                  supplierStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdImporter {
                  id
                  customsRef
                  importerName
                  importerAddress
                  importerCity
                  importerState
                  importerZip
                  importerCountry
                  importerStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdTransport {
                  id
                  transportID
                  port
                  arrivalDate
                  manifestID
                  masterBOL
                  houseBOL
                  containerID
                  countryOfDirect
                  countryOfOrigin
                  warehouseID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdRecords {
                  items {
                    id
                    recordNum
                    description
                    value
                    exchangeRate
                    quantity
                    bcddeclarationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
                BcdAccount {
                  id
                  email
                  firstName
                  lastName
                  phone
                  accountStatus
                  accountType
                  orgName
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdDeclarations {
                    nextToken
                    startedAt
                  }
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBcdAccount = /* GraphQL */ `
  query GetBcdAccount($id: ID!) {
    getBcdAccount(id: $id) {
      id
      email
      firstName
      lastName
      phone
      accountStatus
      accountType
      orgName
      bcdorganizationID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BcdDeclarations {
        items {
          id
          declarationStatus
          declarationType
          declarationSubType
          traderReference
          bcdaccountID
          bcdorganizationID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          BcdSupplier {
            id
            supplierName
            supplierAddress
            supplierCity
            supplierState
            supplierZip
            supplierCountry
            supplierStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdImporter {
            id
            customsRef
            importerName
            importerAddress
            importerCity
            importerState
            importerZip
            importerCountry
            importerStatus
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdTransport {
            id
            transportID
            port
            arrivalDate
            manifestID
            masterBOL
            houseBOL
            containerID
            countryOfDirect
            countryOfOrigin
            warehouseID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          BcdRecords {
            items {
              id
              recordNum
              description
              value
              exchangeRate
              quantity
              bcddeclarationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          BcdAccount {
            id
            email
            firstName
            lastName
            phone
            accountStatus
            accountType
            orgName
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdDeclarations {
              items {
                id
                declarationStatus
                declarationType
                declarationSubType
                traderReference
                bcdaccountID
                bcdorganizationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                BcdSupplier {
                  id
                  supplierName
                  supplierAddress
                  supplierCity
                  supplierState
                  supplierZip
                  supplierCountry
                  supplierStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdImporter {
                  id
                  customsRef
                  importerName
                  importerAddress
                  importerCity
                  importerState
                  importerZip
                  importerCountry
                  importerStatus
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdTransport {
                  id
                  transportID
                  port
                  arrivalDate
                  manifestID
                  masterBOL
                  houseBOL
                  containerID
                  countryOfDirect
                  countryOfOrigin
                  warehouseID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                BcdRecords {
                  items {
                    id
                    recordNum
                    description
                    value
                    exchangeRate
                    quantity
                    bcddeclarationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
                BcdAccount {
                  id
                  email
                  firstName
                  lastName
                  phone
                  accountStatus
                  accountType
                  orgName
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdDeclarations {
                    nextToken
                    startedAt
                  }
                }
              }
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listBcdAccounts = /* GraphQL */ `
  query ListBcdAccounts(
    $filter: ModelBcdAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBcdAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phone
        accountStatus
        accountType
        orgName
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdDeclarations {
          items {
            id
            declarationStatus
            declarationType
            declarationSubType
            traderReference
            bcdaccountID
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdSupplier {
              id
              supplierName
              supplierAddress
              supplierCity
              supplierState
              supplierZip
              supplierCountry
              supplierStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdImporter {
              id
              customsRef
              importerName
              importerAddress
              importerCity
              importerState
              importerZip
              importerCountry
              importerStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdTransport {
              id
              transportID
              port
              arrivalDate
              manifestID
              masterBOL
              houseBOL
              containerID
              countryOfDirect
              countryOfOrigin
              warehouseID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdRecords {
              items {
                id
                recordNum
                description
                value
                exchangeRate
                quantity
                bcddeclarationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            BcdAccount {
              id
              email
              firstName
              lastName
              phone
              accountStatus
              accountType
              orgName
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdDeclarations {
                items {
                  id
                  declarationStatus
                  declarationType
                  declarationSubType
                  traderReference
                  bcdaccountID
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdSupplier {
                    id
                    supplierName
                    supplierAddress
                    supplierCity
                    supplierState
                    supplierZip
                    supplierCountry
                    supplierStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdImporter {
                    id
                    customsRef
                    importerName
                    importerAddress
                    importerCity
                    importerState
                    importerZip
                    importerCountry
                    importerStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdTransport {
                    id
                    transportID
                    port
                    arrivalDate
                    manifestID
                    masterBOL
                    houseBOL
                    containerID
                    countryOfDirect
                    countryOfOrigin
                    warehouseID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdRecords {
                    nextToken
                    startedAt
                  }
                  BcdAccount {
                    id
                    email
                    firstName
                    lastName
                    phone
                    accountStatus
                    accountType
                    orgName
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBcdAccounts = /* GraphQL */ `
  query SyncBcdAccounts(
    $filter: ModelBcdAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBcdAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        firstName
        lastName
        phone
        accountStatus
        accountType
        orgName
        bcdorganizationID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        BcdDeclarations {
          items {
            id
            declarationStatus
            declarationType
            declarationSubType
            traderReference
            bcdaccountID
            bcdorganizationID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            BcdSupplier {
              id
              supplierName
              supplierAddress
              supplierCity
              supplierState
              supplierZip
              supplierCountry
              supplierStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdImporter {
              id
              customsRef
              importerName
              importerAddress
              importerCity
              importerState
              importerZip
              importerCountry
              importerStatus
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdTransport {
              id
              transportID
              port
              arrivalDate
              manifestID
              masterBOL
              houseBOL
              containerID
              countryOfDirect
              countryOfOrigin
              warehouseID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            BcdRecords {
              items {
                id
                recordNum
                description
                value
                exchangeRate
                quantity
                bcddeclarationID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
            BcdAccount {
              id
              email
              firstName
              lastName
              phone
              accountStatus
              accountType
              orgName
              bcdorganizationID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              BcdDeclarations {
                items {
                  id
                  declarationStatus
                  declarationType
                  declarationSubType
                  traderReference
                  bcdaccountID
                  bcdorganizationID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  BcdSupplier {
                    id
                    supplierName
                    supplierAddress
                    supplierCity
                    supplierState
                    supplierZip
                    supplierCountry
                    supplierStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdImporter {
                    id
                    customsRef
                    importerName
                    importerAddress
                    importerCity
                    importerState
                    importerZip
                    importerCountry
                    importerStatus
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdTransport {
                    id
                    transportID
                    port
                    arrivalDate
                    manifestID
                    masterBOL
                    houseBOL
                    containerID
                    countryOfDirect
                    countryOfOrigin
                    warehouseID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  BcdRecords {
                    nextToken
                    startedAt
                  }
                  BcdAccount {
                    id
                    email
                    firstName
                    lastName
                    phone
                    accountStatus
                    accountType
                    orgName
                    bcdorganizationID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                }
                nextToken
                startedAt
              }
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
