import { Typography, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

export const ConvertUserType = (code) => {
  switch (code) {
    case 1:
      return "editor";
    case 2:
      return "manager";
    case 3:
      return "admin";
    case 4:
      return "owner";
    default:
      return "none";
  }
};

const UserType = ({ code, status, offset = false }) => {
  const [userType, setUserType] = useState({ title: "", desc: "" });

  useEffect(() => {
    switch (code) {
      case 1:
        setUserType({
          title: "editor",
          desc: "creation and editing of all owned declarations",
        });
        break;
      case 2:
        setUserType({
          title: "manager",
          desc: "creation & editing of all suppliers and declarations",
        });
        break;
      case 3:
        setUserType({
          title: "admin",
          desc: "creation and editing of all suppliers, users, and declarations, as well as declaration submissions",
        });
        break;
      case 4:
        setUserType({
          title: "owner",
          desc: "creation and editing of all organizations, users, suppliers, and declarations, as well as declaration submissions",
        });
        break;
      default:
        setUserType({ title: "none", desc: "" });
        break;
    }
  }, [code]);

  return (
    <Tooltip title={"Permissions include the " + userType.desc} arrow>
      <Typography
        sx={{ textTransform: "capitalize" }}
        className={status === 1 ? "active" : "inactive"}
        style={{ color: status === 1 ? "initial" : "#ccc" }}
        component="span"
        variant="body2"
      >
        {offset && "("}
        <span style={{ textDecoration: "underline dotted", cursor: "help" }}>
          {userType.title}
        </span>
        {offset && ")"}
      </Typography>
    </Tooltip>
  );
};

export default UserType;
