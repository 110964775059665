/* CRUD for Declarations
 *
 * Legacy Schema:
 * 	ID: Int! autoincrement
 * 		- TODO: leverage uuid()
 * 	BCDNumber: Int
 * 		- TODO: How is this generated?
 * 	traderReference: String
 * 		- DDMMYY### (ex: 251221001)
 *
 * New Schema:
 *  declarationType: Int (see /src/components/application/mapping/DeclarationType.jsx)
 * 	declarationStatus: Int
 * 		- [0: Draft, 1: Ready, 2: Submitted]
 */

import API, { graphqlOperation } from "@aws-amplify/api";
import { Grid, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { ThemeProvider } from "@mui/private-theming";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBcdDeclaration } from "../../../graphql/queries";
import DefaultTheme, { DefaultStyles } from "../../common/Themes";
import Countries from "../mapping/Countries";
import Header, { DrawerHeader } from "../template/Header";
import Title from "../template/Title";
import BcdTextfield from "./BcdTextfield";
// import { v4 as uuid } from "uuid";

const EditDeclaration = () => {
  // const history = useHistory();
  const params = useParams();
  const classes = DefaultStyles();

  const [declaration, setDeclaration] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["General", "Supplier", "Importer", "Transport", "Records"];

  useEffect(() => {
    const getData = async () => {
      try {
        // let data = null;
        const query = { id: params["id"] };
        const response = await API.graphql(
          graphqlOperation(getBcdDeclaration, query)
        );

        // Check if query returned expected data
        if (response.hasOwnProperty("data")) {
          console.log(response["data"].getBcdDeclaration);
          setDeclaration(response["data"].getBcdDeclaration);
        }
      } catch (error) {
        console.log("error on getting declaration", error);
      }
    };
    getData();
  }, [params]);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Box sx={{ display: "flex" }} className={classes.root}>
        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            height: "calc(100vh - 60px)",
          }}
        >
          <DrawerHeader />
          <Title name={"View Declaration"} description="" />
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "4px",
              boxSizing: "border-box",
              height: 400,
              width: "100%",
              maxWidth: "840px",
              margin: "auto",
              flexGrow: 2,
              overflow: "scroll",
              padding: 2,
            }}
          >
            <Stepper
              nonLinear
              activeStep={activeStep}
              alternativeLabel
              sx={{ mb: 3, overflow: "hidden" }}
            >
              {steps.map((label, idx) => (
                <Step key={label}>
                  <StepButton onClick={(e) => setActiveStep(idx)}>
                    <StepLabel>{label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <React.Fragment>{params["id"]}</React.Fragment>
            )}
            {activeStep === 1 && declaration !== null && (
              <Grid
                container
                component="section"
                spacing={3}
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <BcdTextfield
                    prefix="supplier"
                    label="1. Supplier ID #"
                    value={declaration.BcdSupplier.id}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <BcdTextfield
                    prefix="supplier"
                    label="1a. Supplier Name"
                    value={declaration.BcdSupplier.supplierName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <BcdTextfield
                    prefix="supplier"
                    label="1b. Address"
                    value={declaration.BcdSupplier.supplierAddress}
                  />
                  {/* <BcdTextfield
                    prefix="supplier"
                    label="1c. City"
                    value={declaration.BcdSupplier.supplierCity}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <BcdTextfield
                    prefix="supplier"
                    label="1d. State"
                    value={declaration.BcdSupplier.supplierState}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <BcdTextfield
                    prefix="supplier"
                    label="1d. Zip"
                    value={declaration.BcdSupplier.supplierZip}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Countries
                    prefix="supplier"
                    label="1e. Country"
                    active={declaration.BcdSupplier.supplierCountry}
                    setActive={(e) =>
                      setDeclaration((prevState) => ({
                        ...prevState,
                        BcdSupplier: {
                          ...prevState.BcdSupplier,
                          supplierCountry: e.target.value,
                        },
                      }))
                    }
                  />
                  {/* <BcdTextfield
                    prefix="supplier"
                    label="1e. Country"
                    value={declaration.BcdSupplier.supplierCountry}
                  /> */}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default EditDeclaration;
