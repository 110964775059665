/*
 * BCD Database Mapping
 * Table: BCDTypeCode
 *
 * Legacy Schema:
 * 	ID: Int!
 * 	BCDTypeDescription: [Export/Import]
 * 	BCDTypeCode: [E/I]
 */

import { DoubleArrowSharp, LocationCity } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

/* For use on Declaration Create/Edit/View & Submission */
export const ConvertDeclarationType = (code) => {
  switch (code) {
    case "I":
      return "import";
    case "E":
      return "export";
    default:
      return "undefined";
  }
};

/* For use on Declaration Create/Edit/View & Submission */
/* Allowed Type => SubType combinations:
 * - Import => A
 * - Import => B
 * - Import => D
 * - Import => I
 * - Import => P
 * - Import => T
 * - Export => A
 * - Export => E
 */
export const ConvertDeclarationSubType = (code) => {
  switch (code) {
    case "A":
      return "Adjustment";
    case "B":
      return "Bond";
    case "D":
      return "Deposit";
    case "I":
      return "Import";
    case "P":
      return "Por Forma";
    case "T":
      return "Temporary Import";
    case "E":
      return "Export";
    default:
      return "undefined";
  }
};

/* Icon view for Declaration Type overview or other visual requirement */
export const DeclarationIcon = ({ params }) => {
  return (
    <Tooltip title={params.row.declarationType.toUpperCase()} arrow>
      {params.row.declarationType === "import" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoubleArrowSharp sx={{ height: "0.875rem", color: "#acf" }} />
          <LocationCity sx={{ color: "#888" }} />
          <DoubleArrowSharp sx={{ height: "0.875rem", color: "#f4f4f4" }} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoubleArrowSharp sx={{ height: "0.875rem", color: "#f4f4f4" }} />
          <LocationCity sx={{ color: "#888" }} />
          <DoubleArrowSharp sx={{ height: "0.875rem", color: "#acf" }} />
        </Box>
      )}
    </Tooltip>
  );
};

/* Icon view for Declaration Subtype overview or other visual requirement */
export const DeclarationSubType = ({ params }) => {
  return <React.Fragment></React.Fragment>;
};

/* DEPRECATED: Not needed any longer */
const DeclarationType = ({ code, status }) => {
  const [type, setType] = useState(null);

  useEffect(() => {
    switch (code) {
      case "I":
        setType("import");
        break;
      case "E":
        setType("export");
        break;
      default:
        setType("undefined");
        break;
    }
  }, [code]);

  return (
    <Typography
      sx={{ textTransform: "capitalize" }}
      className={status === 1 ? "active" : "inactive"}
      style={{ color: status === 1 ? "initial" : "#ccc" }}
      component="p"
      variant="body2"
    >
      {type}
    </Typography>
  );
};

export default DeclarationType;
