import React from "react";
import { ToggleOff, ToggleOn } from "@mui/icons-material";

const Status = ({ code }) => {
  return (
    <React.Fragment>
      {code === 0 ? (
        <ToggleOff style={{ color: "#ccc" }} />
      ) : (
        <ToggleOn style={{ color: "#00aa00" }} />
      )}
    </React.Fragment>
  );
};

export default Status;
