import { Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

export const ConvertOrgType = (code) => {
  switch (code) {
    case 1:
      return "basic";
    case 2:
      return "advance";
    case 3:
      return "professional";
    case 4:
      return "premium";
    case 5:
      return "administrator";
    default:
      return "none";
  }
};

const OrgType = ({ code, status }) => {
  const [orgType, setOrgType] = useState(null);

  useEffect(() => {
    switch (code) {
      case 1:
        setOrgType("basic");
        break;
      case 2:
        setOrgType("advanced");
        break;
      case 3:
        setOrgType("professional");
        break;
      case 4:
        setOrgType("premium");
        break;
      case 99:
        setOrgType("administrator");
        break;
      default:
        setOrgType("none");
        break;
    }
  }, [code]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ textTransform: "capitalize" }}
        className={status === 1 ? "active" : "inactive"}
        style={{ color: status === 1 ? "initial" : "#ccc" }}
        component="p"
        variant="body2"
      >
        {orgType}
      </Typography>
    </Box>
  );
};

export default OrgType;
